<template>
  <MainContainer>
    <CommonHeader/>
    <SectionHeading>ご利用可能回数確認</SectionHeading>
    <ContentsContainer>
      <ContentsText>最新購入端末に対する利用回数が表示されます。<br>ご不明な点はお問い合わせください。</ContentsText>

      <ContentsTextWrap v-if="isIntegrated">
        <ContentsText><SmallText>表示されている利用可能回数はご利用されている端末全体の回数です。端末ごとの利用可能回数は表示回数以下になる場合があります。お客様の契約状況によって異なりますので詳細はお問い合わせください。</SmallText></ContentsText>
      </ContentsTextWrap>
      <ContentsTextWrap v-else>
        <ContentsText><SmallText>サポート期間は前後する可能性がございます</SmallText></ContentsText>
      </ContentsTextWrap>

      <ContentsHeading>ご契約情報</ContentsHeading>

      <IntegratedWrap v-if="isIntegrated">
        <IntegratedCountContainer>
          <IntegratedCountHeading>お客様のご利用回数</IntegratedcountHeading>
          <IntegratedCountBox>
            <IntegratedCountBoxChild>
              <HeadingLabel>修理</HeadingLabel>
              <IntegratedCountNumber :count="Number.isInteger(integratedCountInfo.numberOfRepair) ? integratedCountInfo.numberOfRepair : null" :hasLostRight="true">残り</IntegratedCountNumber>
              <IntegratedCountAggregationPeriod>
                {{ integratedCountInfo.aggregationPeriodForRepair || `-` }}
              </IntegratedCountAggregationPeriod>
            </IntegratedCountBoxChild>
            <IntegratedCountBoxChild>
              <HeadingLabel>紛失</HeadingLabel>
              <IntegratedCountNumber :count="Number.isInteger(integratedCountInfo.numberOfLost) ? integratedCountInfo.numberOfLost : null" :hasLostRight="integratedCountInfo.hasLostRight">残り</IntegratedCountNumber>
              <IntegratedCountAggregationPeriod>
                {{ integratedCountInfo.aggregationPeriodForLost || `-` }}
              </IntegratedCountAggregationPeriod>
            </IntegratedCountBoxChild>
          </IntegratedCountBox>
        </IntegratedCountContainer>

        <IntegratedDeviceContainer v-for="(integratedDeviceInfo, index) in integratedDeviceInfoList" :key="index" :isApplicableDevice="integratedDeviceInfo.isApplicableDevice">
          <IntegratedDeviceHeading>
            {{ integratedDeviceInfo.deviceName }} ({{displayTel(integratedDeviceInfo)}})
          </IntegratedDeviceHeading>
          <IntegratedDeviceBox>
            <IntegratedDeviceBoxChild>
              <IntegratedDeviceHeadingLabel>サポート期間</IntegratedDeviceHeadingLabel>
              <IntegratedDeviceText>{{ integratedDeviceInfo.supportPeriodFrom || integratedDeviceInfo.supportPeriodTo ? `${integratedDeviceInfo.supportPeriodFrom}~${integratedDeviceInfo.supportPeriodTo}` : `-`  }}</IntegratedDeviceText>
            </IntegratedDeviceBoxChild>
            <IntegratedDeviceBoxChild>
              <IntegratedDeviceHeadingLabel>修理補償</IntegratedDeviceHeadingLabel>
              <IntegratedDeviceText >{{ integratedDeviceInfo.displayMessageForRepair || `-` }}</IntegratedDeviceText>
            </IntegratedDeviceBoxChild>
            <IntegratedDeviceBoxChild>
              <IntegratedDeviceHeadingLabel>紛失補償</IntegratedDeviceHeadingLabel>
              <IntegratedDeviceText>{{ integratedDeviceInfo.displayMessageForLost || `-` }}</IntegratedDeviceText>
            </IntegratedDeviceBoxChild>
          </IntegratedDeviceBox>
        </IntegratedDeviceContainer>
      </IntegratedWrap>
      <template v-else>
        <BorderContainer v-for="(deviceInfo, index) in deviceInfoList" variant="border" :key="index">
          <ItemContainer>
            <HeadingLabel>端末情報</HeadingLabel>
            <ContentsText variant="bold">{{deviceInfo.deviceName}}（{{displayTel(deviceInfo)}}）</ContentsText>
          </ItemContainer>
          <ItemContainer>
            <HeadingLabel>サポート期間</HeadingLabel>
            <template v-if="deviceInfo.serviceType === 1 || deviceInfo.serviceType === 2 || deviceInfo.serviceType === 3 || deviceInfo.serviceType === 6 || deviceInfo.serviceType === 7">
              <ContentsText v-if="deviceInfo.isExpired" variant="bold">サポート期間満了</ContentsText>
              <ContentsText v-else variant="bold">{{deviceInfo.supportPeriodFrom}}~{{deviceInfo.supportPeriodTo}}</ContentsText>
            </template>
            <ContentsText v-else-if="deviceInfo.serviceType === 4" variant="bold">端末登録申請日から２年間</ContentsText>
            <ContentsText v-else-if="deviceInfo.serviceType === 5" variant="bold">対象外</ContentsText>
          </ItemContainer>
          <RightCountContainer v-if="deviceInfo.serviceType === 1">
            <RightCountBox :count="deviceInfo.isExpired ? null : deviceInfo.numberOfRepair">修理補償</RightCountBox>
            <RightCountBox :count="null">紛失補償</RightCountBox>
          </RightCountContainer>
          <RightCountContainer v-else-if="deviceInfo.serviceType === 2">
            <RightCountBox :count="deviceInfo.isExpired ? null : deviceInfo.numberOfRepair">修理補償</RightCountBox>
            <RightCountBox :count="deviceInfo.isExpired ? null : deviceInfo.numberOfLost">紛失補償</RightCountBox>
          </RightCountContainer>
          <RightCountContainer v-else-if="deviceInfo.serviceType === 3">
            <RightCountBox :count="deviceInfo.isExpired ? null : deviceInfo.numberOfInsurance">修理(保険)</RightCountBox>
          </RightCountContainer>
          <ItemContainer v-else-if="deviceInfo.serviceType === 4">
            <ContentsText>利用可能回数のご確認は、<InlineLink @click="entryListLink(deviceInfo.carrierType)">端末情報事前登録一覧画面</InlineLink>にてご確認ください。</ContentsText>
          </ItemContainer>
          <ItemContainer v-else-if="deviceInfo.serviceType === 5">
            <ContentsText><SmallText>本端末はサポート対象外になります。</SmallText></ContentsText>
          </ItemContainer>
          <RightCountContainer v-else-if="deviceInfo.serviceType === 6">
            <RightCountBox :count="deviceInfo.isExpired ? null : deviceInfo.numberOfExchange">利用回数</RightCountBox>
          </RightCountContainer>
          <RightCountContainer v-else-if="deviceInfo.serviceType === 7">
            <RightCountBox :count="deviceInfo.isExpired ? null : deviceInfo.numberOfRepair">修理補償</RightCountBox>
            <RightCountBox :count="deviceInfo.isExpired ? null : deviceInfo.numberOfLost">紛失補償</RightCountBox>
          </RightCountContainer>
        </BorderContainer>
      </template>

      <ButtonContainer>
        <ButtonMain :to="{ name:'rep010', params: {id: this.$route.query.p}}">戻る</ButtonMain>
      </ButtonContainer>
      <CommonSupport/>
      <ButtonContainer>
        <ButtonMain href="https://pass.auone.jp/anshin/#anshin-support">Pontaパス&emsp;マイページ</ButtonMain>
      </ButtonContainer>
    </ContentsContainer>
    <CommonFooter/>
    <CommonLoading v-if="isLoading"/>
  </MainContainer>
</template>

<script>
  import { postRefRightCountApi } from '@/utils/ApiHelper'
  import {$_resultError, $_isError} from '@/utils/Error'

  import CommonHeader from '@/components/common/CommonHeader'
  import CommonFooter from '@/components/common/CommonFooter'
  import CommonSupport from '@/components/common/CommonSupport'
  import CommonLoading from '@/components/common/CommonLoading'
  import MainContainer from '@/components/container/MainContainer'
  import ContentsContainer from '@/components/container/ContentsContainer'
  import BorderContainer from '@/components/container/BorderContainer'
  import ItemContainer from '@/components/container/ItemContainer'
  import SectionHeading from '@/components/heading/SectionHeading'
  import ContentsHeading from '@/components/heading/ContentsHeading'
  import HeadingLabel from '@/components/heading/HeadingLabel'
  import ContentsText from '@/components/text/ContentsText'
  import ContentsTextWrap from '@/components/text/ContentsTextWrap'
  import SmallText from '@/components/text/SmallText'
  import ButtonContainer from '@/components/button/ButtonContainer'
  import ButtonMain from '@/components/button/ButtonMain'

  import RightCountBox from '@/components/text/RightCountBox'
  import RightCountContainer from '@/components/container/RightCountContainer'
  import InlineLink from '@/components/text/InlineLink'
  import IntegratedCountContainer from '@/components/container/IntegratedCountContainer'
  import IntegratedWrap from '@/components/container/IntegratedWrap'
  import IntegratedCountHeading from '@/components/heading/IntegratedCountHeading'
  import IntegratedCountBox from '@/components/text/IntegratedCountBox'
  import IntegratedCountBoxChild from '@/components/text/IntegratedCountBoxChild'
  import IntegratedCountNumber from '@/components/text/IntegratedCountNumber'
  import IntegratedCountAggregationPeriod from '@/components/text/IntegratedCountAggregationPeriod'
  import IntegratedDeviceContainer from '@/components/container/IntegratedDeviceContainer'
  import IntegratedDeviceHeading from '@/components/heading/IntegratedDeviceHeading'
  import IntegratedDeviceBox from '@/components/text/IntegratedDeviceBox'
  import IntegratedDeviceBoxChild from '@/components/text/IntegratedDeviceBoxChild'
  import IntegratedDeviceHeadingLabel from '@/components/heading/IntegratedDeviceHeadingLabel'
  import IntegratedDeviceText from '@/components/text/IntegratedDeviceText'

  export default {
    name: "rep050",
    components: {
      InlineLink,
      RightCountContainer,
      RightCountBox,
      CommonHeader,
      CommonFooter,
      CommonSupport,
      CommonLoading,
      MainContainer,
      ContentsContainer,
      ContentsTextWrap,
      BorderContainer,
      ItemContainer,
      SectionHeading,
      ContentsHeading,
      HeadingLabel,
      ContentsText,
      SmallText,
      ButtonContainer,
      ButtonMain,
      IntegratedWrap,
      IntegratedCountContainer,
      IntegratedCountHeading,
      IntegratedCountBox,
      IntegratedCountBoxChild,
      IntegratedCountNumber,
      IntegratedCountAggregationPeriod,
      IntegratedDeviceContainer,
      IntegratedDeviceHeading,
      IntegratedDeviceBox,
      IntegratedDeviceBoxChild,
      IntegratedDeviceHeadingLabel,
      IntegratedDeviceText,
    },
    data () {
      return {
        deviceInfoList: [],
        isIntegrated: false,
        integratedCountInfo: {},
        integratedDeviceInfoList: [],
        isLoading: false,
        resultError: []
      }
    },
    methods: {
      entryListLink (carrierType) {
        this.$store.dispatch('setCarrierType', carrierType)
        this.$router.push({ name:'rep030', query: {'p': this.$route.query.p}})
      },
      displayTel(deviceInfo) {
        return deviceInfo.isAssociatedWithTel ? deviceInfo.tel : "au回線契約なし"
      }
    },
    mounted () {
      this.isLoading = true
      postRefRightCountApi()
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            this.deviceInfoList = result.deviceInfoList
            this.isIntegrated = result.isIntegrated
            this.integratedCountInfo = result.integratedCountInfo
            this.integratedDeviceInfoList = result.integratedDeviceInfoList
            this.isLoading = false
          }
        })
        .catch(() => {
          this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
        })
    }
  }
</script>
<style lang="scss" scoped>
</style>

<template>
  <MainContainer>
    <CommonHeader/>

    <div v-show="isEdit">
      <SectionHeading>紛失補償申請</SectionHeading>
      <ContentsContainer>
        <ErrorBox v-if="isError">
          <p v-for="(message, index) in errorMessages" :key="index">{{message.errorMessage}}</p>
        </ErrorBox>
        <ContentsText>
          記入内容に不備があった場合は、申請一覧画面にてお知らせいたします。不備内容をご確認のうえ、修正をお願いします。<br>
          お客さま情報は、前日、前々日の情報をもとに表示しております。<br>
          ※au iPhone故障紛失サポートセンターで修理交換サービスご利用の場合は、<InlineLink href="https://pass.auone.jp/anshin/#anshin-support">Pontaパスマイページ</InlineLink>の修理代金サポート（修理補償）からご申請ください。
        </ContentsText>
      </ContentsContainer>

      <ContentsContainer>
        <ContentsHeading>お客さま情報</ContentsHeading>
        <ItemContainer>
          <HeadingLabel>ご契約者名</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.contractorName}} さま</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>電話番号</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.tel}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>現在のご利用端末</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.device}}</ContentsText>
        </ItemContainer>
      </ContentsContainer>

      <ContentsContainer>
        <ContentsHeading>お客さま入力項目</ContentsHeading>
        <ItemContainer>
          <HeadingLabel>サポート対象端末</HeadingLabel>
          <ContentsText>
            <SmallText>申請する端末を選択してください。</SmallText>
          </ContentsText>
          <FormSelect
              v-model="form.deviceKind"
              name="deviceKind"
              :options="deviceKindOption"
              :error="'appDevice' in errorMessage && form.deviceKind !== 1"
              @input="selectDeviceKind"
          ></FormSelect>
          <FormSelect
              v-model="form.appDevice"
              name="appDevice"
              v-if="form.deviceKind === 1"
              :disabled="form.deviceKind !== 1"
              :options="appDeviceKindOption"
              :error="'appDevice' in errorMessage"
          ></FormSelect>
          <error-message v-if="'deviceKind' in errorMessage && !form.deviceKind">{{errorMessage.deviceKind}}</error-message>
          <error-message v-if="'appDevice' in errorMessage && form.deviceKind">{{errorMessage.appDevice}}</error-message>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>紛失・盗難</HeadingLabel>
          <FormRadio
              v-model="form.lostType"
              name="lostType"
              :options="lostTypeOption"
              :error="'lostType' in errorMessage"
          ></FormRadio>
          <error-message v-if="'lostType' in errorMessage">{{errorMessage.lostType}}</error-message>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>紛失・盗難日</HeadingLabel>
          <FormDate
              v-model="form.lostDate"
              name="lostDate"
              :error="'lostDate' in errorMessage"
          ></FormDate>
          <error-message v-if="'lostDate' in errorMessage">{{errorMessage.lostDate}}</error-message>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>紛失・盗難場所</HeadingLabel>
          <FormRadio
              v-model="form.lostPlace"
              name="lostPlace"
              :options="lostPlaceOption"
              :error="'lostPlace' in errorMessage"
          ></FormRadio>
          <error-message v-if="'lostPlace' in errorMessage">{{errorMessage.lostPlace}}</error-message>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="primary" @click="confirm">確認画面に進む</ButtonMain>
        </ButtonContainer>
        <CommonSupport/>
      </ContentsContainer>
    </div>

    <div v-show="isConfirm">
      <SectionHeading>申請内容の確認</SectionHeading>
      <ContentsContainer>
        <ContentsText>次の内容で申請します。</ContentsText>
        <ItemContainer>
          <HeadingLabel>ご契約者名</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.contractorName}} さま</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>電話番号</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.tel}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>現在のご利用端末</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{basicInfo.device}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>サポート対象端末</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.appDevice}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>紛失・盗難</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{lostTypeText}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>紛失・盗難日</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.lostDate}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>紛失・盗難場所</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{lostPlaceText}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <ContentsText>
            <SmallText>申請中は新しいサポート申請を行うことはできません。<br>審査結果をお待ちいただき、申請一覧に不備が表示された場合は修正してください。</SmallText>
          </ContentsText>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="primary" @click="submit">申請する</ButtonMain>
          <ButtonMain @click="edit">修正する</ButtonMain>
        </ButtonContainer>
        <CommonSupport/>
      </ContentsContainer>
    </div>

    <CommonFooter/>
    <CommonLoading v-if="isLoading"/>
  </MainContainer>
</template>

<script>
  import {
    postClaimInitApi,
    postClaimLostApi,
    postClaimLostCheckApi,
    postClaimLostInsertApi,
    postClaimLostUpdateApi
  } from '@/utils/ApiHelper'
  import {$_resultError, $_validationErrors, $_validationErrorMessage, $_errorMessages, $_isError} from '@/utils/Error'

  import CommonHeader from '@/components/common/CommonHeader'
  import CommonFooter from '@/components/common/CommonFooter'
  import CommonSupport from '@/components/common/CommonSupport'
  import CommonLoading from "@/components/common/CommonLoading"
  import MainContainer from '@/components/container/MainContainer'
  import ContentsContainer from '@/components/container/ContentsContainer'
  import ItemContainer from '@/components/container/ItemContainer'
  import SectionHeading from '@/components/heading/SectionHeading'
  import ContentsHeading from '@/components/heading/ContentsHeading'
  import HeadingLabel from '@/components/heading/HeadingLabel'
  import ContentsText from '@/components/text/ContentsText'
  import SmallText from '@/components/text/SmallText'
  import FormSelect from '@/components/select/FormSelect'
  import FormRadio from '@/components/select/FormRadio'
  import FormDate from '@/components/form/FormDate'
  import ButtonContainer from '@/components/button/ButtonContainer'
  import ButtonMain from '@/components/button/ButtonMain'
  import ErrorBox from '@/components/error/ErrorBox'
  import ErrorMessage from '@/components/error/ErrorMessage'
  import InlineLink from "@/components/text/InlineLink.vue";

  export default {
    name: "rep100",
    components: {
      InlineLink,
      CommonHeader,
      CommonFooter,
      CommonSupport,
      CommonLoading,
      MainContainer,
      ContentsContainer,
      ItemContainer,
      SectionHeading,
      ContentsHeading,
      HeadingLabel,
      ContentsText,
      SmallText,
      FormSelect,
      FormRadio,
      FormDate,
      ButtonMain,
      ButtonContainer,
      ErrorBox,
      ErrorMessage
    },
    data () {
      return {
        status: 'edit',
        form: {
          registrationReservationId: '',
          deviceKind: 0,
          appDevice: '',
          lostDate: '',
          lostType: 1,
          lostPlace: 1
        },
        basicInfo: {
          contractorName: '',
          tel: '',
          device: ''
        },
        modelList: [],
        isLoading: false,
        resultError: []
      }
    },
    created () {
      window.addEventListener('beforeunload', this.beforeunload)
    },
    destroyed () {
      window.removeEventListener('beforeunload', this.beforeunload)
    },
    computed: {
      isUpdate () {
        return this.$store.getters.getIsUpdate
      },
      deviceKindOption () {
        let options = [{label: '選択してください', value: 0, disabled: true}]
        options.push({label: "iPhone", value: 1, disabled: false})
        options.push({label: "iPad", value: 2, disabled: false})
        return options
      },
      appDeviceKindOption () {
        let options = [{label: '選択してください', value: '', disabled: true}]
        for (let model of this.modelList) {
          options.push({label: model, value: model, disabled: false})
        }
        return options
      },
      lostTypeOption () {
        let options = []
        options.push({label: '紛失', value: 1})
        options.push({label: '盗難', value: 2})
        return options
      },
      lostPlaceOption () {
        let options = []
        options.push({label: '国内', value: 1})
        options.push({label: '海外', value: 2})
        return options
      },
      lostTypeText () {
        if (this.form.lostType === '') {
          return ''
        } else {
          return this.lostTypeOption.find(option => option.value === this.form.lostType).label;
        }
      },
      lostPlaceText () {
        if (this.form.lostPlace === '') {
          return ''
        } else {
          return this.lostPlaceOption.find(option => option.value === this.form.lostPlace).label;
        }
      },
      appNumber () {
        return this.$store.getters.getAppNumber
      },
      isEdit () {
        return this.status === 'edit'
      },
      isConfirm () {
        return this.status === 'confirm'
      },
      errors() {
        return $_validationErrors(this.resultError)
      },
      isError () {
        return this.errors.length > 0
      },
      errorMessage () {
        return $_validationErrorMessage(this.resultError)
      },
      errorMessages () {
        return $_errorMessages(this.resultError)
      }
    },
    mounted () {
      this.isLoading = true
      postClaimInitApi().then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        if ($_isError(this.resultError, this.$route)) {
          this.isLoading = false
        } else {
          this.form.registrationReservationId = result.registrationReservationId
          if ('basicInfo' in result) {
            this.basicInfo = result.basicInfo
          }
          this.modelList = result.modelList
          this.claimLost()
        }
      })
    },
    methods: {
      beforeunload (event) {
        event.returnValue = ''
      },
      claimLost () {
        if (!this.isUpdate) {
          this.isLoading = false
          return
        }
        postClaimLostApi(this.form.registrationReservationId)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          $_isError(this.resultError, this.$route)
          for (let key in result) {
            if (Object.prototype.hasOwnProperty.call(this.form, key)) {
              this.form[key] = result[key]
            }
            if (Object.prototype.hasOwnProperty.call(this.basicInfo, key)) {
              this.basicInfo[key] = result[key]
            }
            // deviceKindを調整
            if (key === 'appDevice' && this.form[key]) {
              this.form.deviceKind = this.form[key] === 'iPad' ? 2 : 1
            }
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
        })
      },
      selectDeviceKind(event) {
        if (event.target.value === '2') {
          this.form.appDevice = 'iPad'
        } else {
          this.form.appDevice = ''
        }
      },
      confirm () {
        if (this.isLoading) return false

        this.isLoading = true
        this.resultError = []
        postClaimLostCheckApi(this.form)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            this.status = 'confirm'
          }
          this.scrollTop()
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
        })
      },
      submit () {
        if (this.isLoading) return false
        this.isLoading = true
        this.isUpdate ? this.updateLost() : this.insertLost()
      },
      insertLost () {
        postClaimLostInsertApi(this.form)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            this.$store.dispatch('setReceiptNumber', result.appNumber)
            this.$router.push({name: 'rep160', query: {'p': this.$route.query.p}})
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
        })
      },
      updateLost () {
        postClaimLostUpdateApi({...this.form, appNumber: this.appNumber})
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            this.$store.dispatch('setReceiptNumber', result.appNumber)
            this.$router.push({name: 'rep160', query: {'p': this.$route.query.p}})
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
        })
      },
      edit () {
        this.scrollTop()
        this.status = 'edit'
      },
      scrollTop () {
        this.$scrollTo('body', 1)
      }
    }
  }
</script>

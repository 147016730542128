<template>
  <header class="common-header">
		<div class="header-img">
			<img v-if="isHoken" src="@/assets/images/header_hoken.png" alt="Pontaパス/Pontaパス ライト 修理代金サポート(保険)WEB申込フォーム あんしんサービス" class="image">
			<img v-else src="@/assets/images/header.png" alt="Pontaパス/Pontaパス ライト 修理代金サポートWEB申込フォーム あんしんサービス" class="image">
    </div>
		<div class="header-img-pc">
			<img v-if="isHoken" src="@/assets/images/PC_header_hoken.png" alt="Pontaパス/Pontaパス ライト 修理代金サポート(保険)WEB申込フォーム あんしんサービス" class="image">
			<img v-else src="@/assets/images/PC_header.png" alt="Pontaパス/Pontaパス ライト 修理代金サポートWEB申込フォーム あんしんサービス" class="image">
    </div>
  </header>
</template>

<script>

export default {
  name: 'common-header',
  data() {
    return {
      isHoken: this.$store.getters.getIsHoken
    }
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
@import "@/assets/stylesheets/mixins";
.header{
	margin-bottom: 0;
	background: none;
	@include mq-up(sm) {
		box-shadow: 0 1px 5px 0 rgba(0,0,0,0.20);
	}
}

.header-img{
	text-align: center;
	@include mq-up(sm) {
		display: none;
	}
	.image{
		display: block;
		width: 100%;
	}
}

.header-img-pc{
	display: none;
	text-align: center;
	padding: 0 20px 10px;
	max-width: 940px;
	@include mq-up(sm) {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.image{
		display: block;
		width: auto;
		max-width: 100%;
		padding-top: 7px;
	}
}
</style>

<template>
  <MainContainer>
    <CommonHeader/>
    <SectionHeading>メニューページ</SectionHeading>
    <ContentsContainer>
      <ErrorBox v-if="remainSession">
        <p>前回の申請は完了していません。最初からご入力ください。</p>
      </ErrorBox>
      <ItemContainer>
        <HeadingLabel>ご契約者名</HeadingLabel>
        <ContentsText variant="bold" :breakword="true">{{contractorName}} さま</ContentsText>
        <ContentsText>
          <SmallText variant="non-asterisk">
            ※ご契約もしくは機種変更、本体交換当日の場合には表示される情報が最新でないことがあるため、翌日以降にご利用ください。<br>
            ※申請内容が補償の条件を満たさない場合には適用できない場合があります。
          </SmallText>
        </ContentsText>
      </ItemContainer>
      <ItemContainer>
        <ButtonBox v-if="this.$route.params.id == '4'" :to="{name: 'rep023', query: {'p': this.$route.params.id}}" :disabled="!canPushClaimButton">
          <template v-slot:header>端末情報事前登録を申請する</template>
        </ButtonBox>
        <ButtonBox v-else :to="{name: 'rep021', query: {'p': this.$route.params.id}}" :disabled="!canPushClaimButton">
          <template v-slot:header>修理代金サポートを申請する</template>
        </ButtonBox>
        <ButtonBox :to="{name: 'rep040', query: {'p': this.$route.params.id}}">
          <template v-slot:header>申請一覧を確認する</template>
        </ButtonBox>
        <ButtonBox :to="{name: 'rep050', query: {'p': this.$route.params.id}}">
          <template v-slot:header>利用可能回数を確認する</template>
        </ButtonBox>
        <ButtonBox v-if="canDisplayRegisteredDeviceList" :to="{name: 'rep030', query: {'p': this.$route.params.id}}">
          <template v-slot:header>修理代金サポート（保険）&emsp;端末情報事前登録一覧を確認する</template>
        </ButtonBox>
      </ItemContainer>
      <CommonSupport/>
      <ButtonContainer>
        <ButtonMain href="https://pass.auone.jp/anshin/#anshin-support">Pontaパス&emsp;マイページ</ButtonMain>
      </ButtonContainer>
    </ContentsContainer>
    <CommonFooter/>
    <CommonLoading v-if="isLoading"/>
  </MainContainer>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import CommonSupport from '@/components/common/CommonSupport'
import CommonLoading from '@/components/common/CommonLoading'
import MainContainer from '@/components/container/MainContainer'
import SectionHeading from '@/components/heading/SectionHeading'
import ContentsContainer from '@/components/container/ContentsContainer'
import ErrorBox from '@/components/error/ErrorBox'
import ItemContainer from '@/components/container/ItemContainer'
import HeadingLabel from '@/components/heading/HeadingLabel'
import ContentsText from '@/components/text/ContentsText'
import SmallText from '@/components/text/SmallText'
import ButtonBox from '@/components/button/ButtonBox'
import ButtonContainer from '@/components/button/ButtonContainer'
import ButtonMain from '@/components/button/ButtonMain'

import {postInitMenuApi} from '@/utils/ApiHelper'
import {$_resultError, $_isError} from '@/utils/Error'

export default {
  name: "rep010",
  components: {
    CommonHeader,
    CommonFooter,
    CommonSupport,
    CommonLoading,
    MainContainer,
    SectionHeading,
    ContentsContainer,
    ErrorBox,
    ItemContainer,
    HeadingLabel,
    ContentsText,
    SmallText,
    ButtonBox,
    ButtonContainer,
    ButtonMain,
  },
  data() {
    return {
      isLoading: false,
      contractorName: '',
      canDisplayRegisteredDeviceList: false,
      remainSession: false,
      canPushClaimButton: false,
      resultError: []
    }
  },
  computed: {
  },
  mounted () {
    this.isLoading = true
    postInitMenuApi()
    .then(res => {
      const result = res.data.result
      this.resultError = $_resultError(result)
      $_isError(this.resultError, this.$route)
      this.canDisplayRegisteredDeviceList = result.canDisplayRegisteredDeviceList
      this.contractorName = result.contractorName
      this.remainSession = result.remainSession
      this.canPushClaimButton = result.canPushClaimButton
      this.isLoading = false
    })
    .catch(() => {
      this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
    })
  }
}
</script>
<style lang="scss" scoped>
  .button-box {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
</style>

<template>
  <MainContainer>
    <CommonHeader/>
    <SectionHeading>申請完了</SectionHeading>
    <ContentsContainer>
      <ContentsText>修理代金サポートのお申込みありがとうございます。<br>申請を受付いたしました。<br><span class="important">申請番号をお控えいただき</span>、ご不明点は修理代金サポートセンターまでお問合せください。<br>申請状況はメニュー画面でご確認いただけます。<br>申請内容に不備がある場合にはSMS（※）でお知らせいたしますので、不備理由をご確認いただき不備の修正をお願いいたします。<br>記載された不備理由の修正後に再度不備になる場合には、改めてSMSにてお知らせいたします。<br>※au、UQ、povo以外をご利用のお客さまの場合はSMSでのご連絡はできかねます。 申請後の状況は申請一覧画面にてご確認ください。</ContentsText>
      <NumberContainer>
        <span class="label">申請番号</span>
        <br>
        <span class="number">{{receiptNumber}}</span>
      </NumberContainer>
      <ButtonContainer>
        <ButtonMain variant="primary" :to="{name: 'rep040', query: {'p': this.$route.query.p}}">申請一覧画面へ</ButtonMain>
      </ButtonContainer>
      <CommonSupport />
      <ButtonContainer>
        <ButtonMain href="https://pass.auone.jp/anshin/#anshin-support">Pontaパス&emsp;マイページ</ButtonMain>
      </ButtonContainer>
    </ContentsContainer>
    <CommonFooter/>
    <CommonLoading v-if="isLoading" />
  </MainContainer>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import CommonSupport from '@/components/common/CommonSupport'
import CommonLoading from '@/components/common/CommonLoading'
import MainContainer from '@/components/container/MainContainer'
import ContentsContainer from '@/components/container/ContentsContainer'
import NumberContainer from '@/components/container/NumberContainer'
import SectionHeading from '@/components/heading/SectionHeading'
import ContentsText from '@/components/text/ContentsText'
import ButtonContainer from '@/components/button/ButtonContainer'
import ButtonMain from '@/components/button/ButtonMain'
import {postUtilAuthApi} from '@/utils/ApiHelper'
import {$_resultError, $_isError} from '@/utils/Error'

export default {
  name: "rep160",
  components: {
    CommonHeader,
    CommonFooter,
    CommonSupport,
    CommonLoading,
    MainContainer,
    ContentsContainer,
    NumberContainer,
    SectionHeading,
    ContentsText,
    ButtonContainer,
    ButtonMain
  },
  data() {
    return {
      isLoading: false,
      resultError: []
    }
  },
  computed: {
    receiptNumber() {
      return this.$store.getters.getReceiptNumber
    },
  },
  mounted () {
    this.isLoading = true
    postUtilAuthApi()
    .then(res => {
      const result = res.data.result
      this.resultError = $_resultError(result)
      $_isError(this.resultError, this.$route)
      this.isLoading = false
    })
    .catch(() => {
      this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
    })
  }
}
</script>
<style lang="scss" scoped>
</style>
